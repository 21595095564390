<div class="category-component">
  <div class="category-info" (click)="toggleExpansion()" [id]="category.id">
    <div class="left">
      <div class="image-placeholder" *ngIf="!expanded && !category.image"></div>
      <div class="category-image" *ngIf="!expanded && category.image">
        <img [src]="category.image" />
      </div>
      <div class="category-name">
        <div class="content">
          {{ category.name }}
        </div>
      </div>
    </div>
    <div class="expansions">
      <fa-icon *ngIf="!expanded" class="icon" [icon]="chevronDown"></fa-icon>
      <fa-icon *ngIf="expanded" class="icon" [icon]="chevronUp"></fa-icon>
    </div>
  </div>

  <div class="category-border" *ngIf="!expanded"></div>
  <div class="category-items" *ngIf="expanded">
    <div *ngIf="category.description" class="category-description">
      {{ category.description }}
    </div>
    <div
      *ngFor="let item of category.items; last as isLast; first as isFirst"
      class="category-item"
      (click)="navigateToItem(item)"
    >
      <div class="item-border" *ngIf="isFirst"></div>
      <div
        class="item-info"
        *ngIf="item.isActive === undefined || item.isActive"
      >
        <div class="item-content">
          <img *ngIf="item.image" [src]="item.image" />
          <div class="right">
            <div class="item-name">{{ item.name }}</div>
            <div class="item-description">
              {{ item.description }}
            </div>
            <div class="bottom-row">
              <div *ngIf="item.price > 0">
                ${{ item.price | number: "1.2" }}
              </div>
              <fa-icon class="chevron" [icon]="chevronRight"></fa-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="item-border" *ngIf="!isLast"></div>
    </div>
    <div class="close-category" *ngIf="expanded" (click)="returnToTop()">
      <fa-icon class="icon" [icon]="chevronUp"></fa-icon>
    </div>
  </div>
</div>
<div
  *ngIf="addPhotoModalOpen"
  class="add-photo-modal"
  (click)="closeAddPhotoModule()"
>
  <app-photo-uploader
    [itemId]="imageItemId"
    (closeModalEvent)="closeAddPhotoModule()"
  ></app-photo-uploader>
</div>
